import PropTypes from "prop-types";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Page from "../Page";
import MainLayout from "../../MainLayout";
import AdminLayout from "../../AdminLayout";
import BarSpinner from "../BarSpinner";

const Route = ({ children, header, layout, title, ...rest }) => {
  let Layout;
  const layoutType = layout;
  const { isAuthenticated, isLoading } = useAuth0();

  switch (layoutType) {
    case "admin": {
      Layout = AdminLayout;
      break;
    }

    case "main": {
      Layout = MainLayout;
      break;
    }
    default: {
      // noop
    }
  }

  return (
    <Page
      isAuthenticated={isAuthenticated}
      isLoading={isLoading}
      renderLoading={<BarSpinner />}
      {...rest}
    >
      {Layout ? (
        <Layout header={header} title={title}>
          {children}
        </Layout>
      ) : (
        children
      )}
    </Page>
  );
};

Route.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.shape({}),
  layout: PropTypes.oneOf(["main", "admin", "skip"]),
  title: PropTypes.string,
};

Route.defaultProps = {
  header: {
    icon: "documents",
    leftContainer: null,
    rightContainer: null,
    title: "",
  },
  layout: "main",
  title: null,
};

export default Route;
