// NOTE: this file is generated automatically, any changes will be overwritten.

import PropTypes from "prop-types";
import React from "react";
import Box from "../base/Box";
import about from "../../images/icons/about.inline.svg";
import access from "../../images/icons/access.inline.svg";
import account from "../../images/icons/account.inline.svg";
import analytics from "../../images/icons/analytics.inline.svg";
import associationunits from "../../images/icons/association-units.inline.svg";
import availablenodes from "../../images/icons/available-nodes.inline.svg";
import availablesims from "../../images/icons/available-sims.inline.svg";
import buses from "../../images/icons/buses.inline.svg";
import calculator from "../../images/icons/calculator.inline.svg";
import charts from "../../images/icons/charts.inline.svg";
import checksquare from "../../images/icons/check-square.inline.svg";
import checkboxActive from "../../images/icons/checkboxActive.inline.svg";
import checkboxInactive from "../../images/icons/checkboxInactive.inline.svg";
import chevrondown from "../../images/icons/chevron-down.inline.svg";
import chevronright from "../../images/icons/chevron-right.inline.svg";
import cities from "../../images/icons/cities.inline.svg";
import edit from "../../images/icons/edit.inline.svg";
import forwardarrow from "../../images/icons/forward-arrow.inline.svg";
import gps from "../../images/icons/gps.inline.svg";
import home from "../../images/icons/home.inline.svg";
import isstation from "../../images/icons/is-station.inline.svg";
import laptop from "../../images/icons/laptop.inline.svg";
import lines from "../../images/icons/lines.inline.svg";
import mappin from "../../images/icons/map-pin.inline.svg";
import menugrid from "../../images/icons/menu-grid.inline.svg";
import morehorizontal from "../../images/icons/more-horizontal.inline.svg";
import nodestatusmenu from "../../images/icons/node-status-menu.svg";
import nodestatus from "../../images/icons/node-status.inline.svg";
import notstation from "../../images/icons/not-station.inline.svg";
import offstatus from "../../images/icons/off-status.inline.svg";
import offline from "../../images/icons/offline.inline.svg";
import profile from "../../images/icons/profile.inline.svg";
import radioActive from "../../images/icons/radioActive.inline.svg";
import radioInactive from "../../images/icons/radioInactive.inline.svg";
import remove from "../../images/icons/remove.svg";
import routes from "../../images/icons/routes.inline.svg";
import search from "../../images/icons/search.inline.svg";
import serviceStatus from "../../images/icons/serviceStatus.inline.svg";
import services from "../../images/icons/services.inline.svg";
import simassociation from "../../images/icons/sim-association.inline.svg";
import sims from "../../images/icons/sims.inline.svg";
import square from "../../images/icons/square.inline.svg";
import stack from "../../images/icons/stack.inline.svg";
import subsystem from "../../images/icons/sub-system.inline.svg";
import systemstatus from "../../images/icons/system-status.inline.svg";
import system from "../../images/icons/system.inline.svg";
import ticketmanagement from "../../images/icons/ticket-management.inline.svg";
import units from "../../images/icons/units.inline.svg";
import users from "../../images/icons/users.inline.svg";
import wiwilogo from "../../images/icons/wiwi-logo.svg";

const svgMap = {
  about: about,
  access: access,
  account: account,
  analytics: analytics,
  "association-units": associationunits,
  "available-nodes": availablenodes,
  "available-sims": availablesims,
  buses: buses,
  calculator: calculator,
  charts: charts,
  "check-square": checksquare,
  checkboxActive: checkboxActive,
  checkboxInactive: checkboxInactive,
  "chevron-down": chevrondown,
  "chevron-right": chevronright,
  cities: cities,
  edit: edit,
  "forward-arrow": forwardarrow,
  gps: gps,
  home: home,
  "is-station": isstation,
  laptop: laptop,
  lines: lines,
  "map-pin": mappin,
  "menu-grid": menugrid,
  "more-horizontal": morehorizontal,
  "node-status-menu": nodestatusmenu,
  "node-status": nodestatus,
  "not-station": notstation,
  "off-status": offstatus,
  offline: offline,
  profile: profile,
  radioActive: radioActive,
  radioInactive: radioInactive,
  remove: remove,
  routes: routes,
  search: search,
  serviceStatus: serviceStatus,
  services: services,
  "sim-association": simassociation,
  sims: sims,
  square: square,
  stack: stack,
  "sub-system": subsystem,
  "system-status": systemstatus,
  system: system,
  "ticket-management": ticketmanagement,
  units: units,
  users: users,
  "wiwi-logo": wiwilogo,
};

const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg];

  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  );
};

Icon.propTypes = {
  svg: PropTypes.oneOf([
    "about",
    "access",
    "account",
    "analytics",
    "association-units",
    "available-nodes",
    "available-sims",
    "buses",
    "calculator",
    "charts",
    "check-square",
    "checkboxActive",
    "checkboxInactive",
    "chevron-down",
    "chevron-right",
    "cities",
    "edit",
    "forward-arrow",
    "gps",
    "home",
    "is-station",
    "laptop",
    "lines",
    "map-pin",
    "menu-grid",
    "more-horizontal",
    "node-status-menu",
    "node-status",
    "not-station",
    "off-status",
    "offline",
    "profile",
    "radioActive",
    "radioInactive",
    "remove",
    "routes",
    "search",
    "serviceStatus",
    "services",
    "sim-association",
    "sims",
    "square",
    "stack",
    "sub-system",
    "system-status",
    "system",
    "ticket-management",
    "units",
    "users",
    "wiwi-logo",
  ]).isRequired,
};

export default Icon;
