import React from "react";
import PropTypes from "prop-types";
import Box from "../base/Box";
import AdminHeader from "../AdminHeader";
import NavigationData from "../GeneralComponent/NavigationData";
import SideNavigation from "../GeneralComponent/SideNavigation";
// import Footer from "../base/Footer";

const AdminLayout = ({ children, ...rest }) => (
  <Box sx={{ display: "flex", height: "100%" }}>
    <SideNavigation
      headingSx={{ p: 5, pt: 9 }}
      logo="wiwi-logo"
      menuSx={{ p: 0 }}
    >
      {({ open }) => <NavigationData open={open} />}
    </SideNavigation>
    <Box sx={{ height: "100%", left: 0, position: "absolute", width: "100%" }}>
      <AdminHeader />
      <Box as="main" sx={{ height: "100%" }}>
        {typeof children === "function" ? children(rest) : children}
      </Box>
    </Box>
    {/* <Footer /> */}
  </Box>
);

AdminLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default AdminLayout;
