import React from "react";
import propTypes from "prop-types";
import Box from "../Box";
import Img from "../Img";
import Icon from "../../Icon";

const Image = ({ children, svgIcon, img, svg, bg, sx, ...rest }) => {
  if (img) {
    return <Img src={img} sx={sx} {...rest} />;
  }
  if (svg || svgIcon) {
    return svg || <Icon svg={svgIcon} sx={sx} {...rest} />;
  }
  if (bg || children) {
    return (
      <Box sx={{ backgroundImage: `${bg}`, ...sx }} {...rest}>
        {children}
      </Box>
    );
  }
  return <Box />;
};
Image.propTypes = {
  bg: propTypes.string,
  children: propTypes.node,
  img: propTypes.string || null,
  svg: propTypes.node,
  svgIcon: propTypes.string,
  sx: propTypes.shape({}),
};

Image.defaultProps = {
  bg: "",
  children: <Box />,
  img: null,
  svg: null,
  svgIcon: "",
  sx: {},
};
export default Image;
