import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import MenuItemComponent from "../MenuItemComponent";

const NavigationData = ({ sx, open }) => {
  const menuData = [
    {
      menu: [
        {
          icon: "home",
          label: "Inicio",
          path: "/",
        },
        {
          icon: "account",
          label: "Administrar Usuarios",
          submenu: [
            {
              icon: "users",
              label: "Usuarios",
              path: "/users",
            },
            {
              icon: "access",
              label: "Gestión de Acceso",
              path: "#",
            },
            {
              icon: "profile",
              label: "Perfil",
              path: "#",
            },
            {
              icon: "laptop",
              label: "Ajustes",
              path: "#",
            },
          ],
        },
        {
          icon: "stack",
          label: "Flotilla",
          submenu: [
            {
              icon: "cities",
              label: "Ciudades",
              path: "/cities",
            },
            {
              icon: "system",
              label: "Sistemas",
              path: "/systems",
            },
            {
              icon: "sub-system",
              label: "Subsistemas",
              path: "/subsystems",
            },
            {
              icon: "lines",
              label: "Lineas",
              path: "/lines",
            },
            {
              icon: "units",
              label: "Unidades",
              path: "/units",
            },
            {
              icon: "sims",
              label: "SIMs",
              path: "/sims",
            },
            {
              icon: "routes",
              label: "Nodos",
              path: "/nodes",
            },
            {
              icon: "sim-association",
              label: "Asociación Nodo-SIM",
              path: "/simAssociation",
            },
            {
              icon: "association-units",
              label: "Asociación Nodo-Unidad",
              path: "/unitAssociation",
            },
            {
              icon: "available-nodes",
              label: "Nodos no asignados",
              path: "/availableNodes",
            },
            {
              icon: "available-sims",
              label: "SIMs no asignados",
              path: "/availableSims",
            },
            /* {
              action: () => {},
              icon: "available-nodes",
              label: "Nodos Disponibles",
            }, */
          ],
        },
        {
          icon: "system-status",
          label: "Estado del sistema",
          submenu: [
            {
              icon: "off-status",
              label: "Estado desactivado",
              path: "#",
            },
            {
              icon: "offline",
              label: "Dispositivos sin conexión",
              path: "#",
            },
            {
              icon: "buses",
              label: "Autobuses",
              path: "#",
            },
            {
              icon: "analytics",
              label: "Analítica",
              path: "#",
            },
            {
              icon: "ticket-management",
              label: "Gestión de tickets",
              path: "#",
            },
            {
              icon: "gps",
              label: "Módulo GPS",
              path: "#",
            },
          ],
        },
        {
          icon: "serviceStatus",
          label: "Estado del servicio",
          path: "/serviceStatus",
        },
        {
          icon: "node-status-menu",
          label: "Estatus Nodo",
          path: "/nodeStatus",
        },
      ],
    },
  ];
  return (
    <Box
      sx={{
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-thumb": {
          background: "bgMenu",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-track": {
          borderRadius: "10px",
        },
        mt: 8,
        overflowY: "scroll",
        ...sx,
      }}
    >
      {menuData.map((menuItem, i) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: i === menuData.length - 1 ? 8 : 0,
          }}
        >
          {menuItem.sectionLabel && (
            <Box
              sx={{
                color: "grays.3",
                my: 5,
                opacity: open ? 1 : 0,
                px: 5,
                transition: "opacity 0.5s",
              }}
            >
              {menuItem.sectionLabel}
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {menuItem.menu.map((item) => (
              <MenuItemComponent menuData={item} open={open} />
            ))}
          </Box>
          <Box />
        </Box>
      ))}
    </Box>
  );
};

NavigationData.propTypes = {
  open: PropTypes.bool,
  sx: PropTypes.shape({}),
};

NavigationData.defaultProps = {
  open: false,
  sx: {},
};

export default NavigationData;
