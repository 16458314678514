import React from "react";
import { BarLoader } from "react-spinners";
import { useTheme } from "@chakra-ui/react";
import Box from "../Box";

const BarSpinner = (props) => {
  const theme = useTheme();
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      py={7}
      width="100%"
      {...props}
    >
      <BarLoader color={theme.colors.text.primary} />
    </Box>
  );
};

export default BarSpinner;
