import React from "react";
import PropTypes from "prop-types";
import Box from "../Box";
import Container from "../Container";

const Section = ({
  appendContent,
  children,
  containerSx,
  fancyHeading,
  headingProps,
  heading,
  headingSx,
  outerChildren,
  scrollToID,
  scrollToIDSx,
  sx,
  ...rest
}) => {
  const sectionStyled = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    mb: [6, null, null, 8, null],
    mt: [6, null, null, 8, null],
    pb: [6, null, null, 8, null],
    position: "relative",
    pt: [6, null, null, 8, null],
    // zIndex: 1,
    ...sx,
  };
  return (
    <Box as="section" sx={sectionStyled} {...rest}>
      {appendContent}
      {outerChildren && outerChildren}
      <Container sx={{ ...containerSx }}>{children}</Container>
    </Box>
  );
};

Section.propTypes = {
  appendContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  containerSx: PropTypes.shape({}),
  fancyHeading: PropTypes.string,
  heading: PropTypes.string,
  headingProps: PropTypes.shape({}),
  headingSx: PropTypes.shape({}),
  outerChildren: PropTypes.node,
  scrollToID: PropTypes.string,
  scrollToIDSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Section.defaultProps = {
  appendContent: null,
  containerSx: {},
  fancyHeading: "",
  heading: "",
  headingProps: {},
  headingSx: {},
  outerChildren: null,
  scrollToID: "",
  scrollToIDSx: {},
  sx: {},
};

export default Section;
