import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "@chakra-ui/react";
import Box from "../Box";

const ToggleCard = ({ children, toggle, sx, position, carterSx }) => {
  let carterStyle = {};

  switch (position) {
    case "topRight": {
      carterStyle = {
        right: "15px",
        top: "-5px",
      };
      break;
    }
    case "topLeft": {
      carterStyle = {
        left: "15px",
        top: "-5px",
      };
      break;
    }
    case "bottomRight": {
      carterStyle = {
        bottom: "-5px",
        right: "15px",
      };
      break;
    }
    case "bottomLeft": {
      carterStyle = {
        bottom: "-5px",
        left: "15px",
      };
      break;
    }
    default: {
      carterStyle = {
        right: "15px",
        top: "-5px",
      };
      break;
    }
  }
  return (
    <Collapse animateOpacity in={toggle}>
      <Box
        _before={{
          bg: "grays.2",
          borderLeft: "0.5px solid",
          borderLeftColor: "grays.3",
          borderRadius: 1,
          borderTop: "0.5px solid",
          borderTopColor: "grays.3",
          content: '""',
          height: "9px",
          position: "absolute",
          transform: "rotate(45deg)",
          width: "10px",
          ...carterStyle,
          ...carterSx,
        }}
        sx={{
          bg: "grays.2",
          borderRadius: 2,
          boxShadow: 3,
          minWidth: "150px",
          p: 4,
          position: "absolute",
          right: 0,
          top: 12,
          zIndex: 4,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Collapse>
  );
};

ToggleCard.propTypes = {
  carterSx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  sx: PropTypes.shape({}),
  toggle: PropTypes.bool,
};

ToggleCard.defaultProps = {
  carterSx: {},
  position: "topLeft",
  sx: {},
  toggle: false,
};

export default ToggleCard;
