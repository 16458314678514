import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Link } from "gatsby";
import Box from "../../base/Box";
import Image from "../../base/Image";
import ToggleCard from "../../base/ToggleCard";

const UserMenu = ({
  sx,
  user,
  profileImage,
  profileHoverSx,
  profileSx,
  online,
  menuList,
}) => {
  const [menuTrigger, setMenuTrigger] = useState(false);
  const menuToggle = () => {
    setMenuTrigger(!menuTrigger);
  };
  const drop = useRef(null);
  const outerClick = (e) => {
    if (
      !e.target.closest(`.${drop.current.className}`) &&
      (menuToggle || menuTrigger)
    ) {
      setMenuTrigger(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", outerClick);
    return () => {
      document.removeEventListener("click", outerClick);
    };
  });
  const profileStyle = {
    ":hover": {
      alignItems: "center",
      borderRadius: 7,
      boxShadow: "0 0 5px 5px #d6d6d6",
      display: "flex",
      height: "45px",
      justifyContent: "center",
      width: "45px",
      ...profileHoverSx,
    },
    alignItems: "center",
    bg: "bgMenu",
    borderRadius: 7,
    boxShadow: 0,
    color: "white",
    display: "flex",
    fontSize: "30px",
    height: "45px",
    justifyContent: "center",
    textTransform: "uppercase",
    transition: "box-shadow 0.1s",
    width: "45px",
  };
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        ...sx,
      }}
    >
      <Box
        ref={drop}
        as="button"
        className="drop-menu"
        onClick={(event) => {
          menuToggle();
          event.stopPropagation();
        }}
        sx={{ ...profileStyle, ...profileSx }}
      >
        {profileImage ? (
          <Image
            img={profileImage}
            sx={{ borderRadius: "50%", height: "45px", width: "45px" }}
          />
        ) : (
          <Box>{user.firstName[0]}</Box>
        )}
      </Box>
      {online && (
        <Box
          sx={{
            bg: "onlineGreen",
            borderRadius: "50%",
            bottom: 0,
            height: "15px",
            position: "absolute",
            right: 0,
            width: "15px",
          }}
        />
      )}
      <ToggleCard position="topRight" sx={{ p: 0 }} toggle={menuTrigger}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {menuList.map((item, index) => (
            <Box sx={{ color: "grays.5" }}>
              {index !== 0 && (
                <Box
                  sx={{
                    borderTop: "2px solid",
                    borderTopColor: "grays.9",
                    left: 0,
                    position: "absolute",
                    width: "100%",
                  }}
                />
              )}
              {get(item, "data") && (
                <Box
                  _hover={{ bg: "lightGreen", color: "mediumGreen" }}
                  sx={{ cursor: "default", my: 3, px: 6, py: 4 }}
                >
                  {item.data}
                </Box>
              )}
              {get(item, "section") && (
                <Box sx={{ my: 4 }}>
                  {item.values.map((sectionItem) => (
                    <Box>
                      {sectionItem.path && (
                        <Box
                          _hover={{ bg: "lightGreen", color: "mediumGreen" }}
                          sx={{ px: 6, py: 1 }}
                        >
                          <Link to={sectionItem.path}>{sectionItem.label}</Link>
                        </Box>
                      )}
                      {sectionItem.action && (
                        <Box
                          _hover={{ bg: "lightGreen", color: "mediumGreen" }}
                          sx={{ px: 6, py: 1 }}
                        >
                          <Box as="button" onClick={sectionItem.action}>
                            {sectionItem.label}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </ToggleCard>
    </Box>
  );
};

UserMenu.propTypes = {
  menuList: PropTypes.arrayOf(PropTypes.shape({})),
  online: PropTypes.bool,
  profileHoverSx: PropTypes.shape({}),
  profileImage: PropTypes.string,
  profileSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};

UserMenu.defaultProps = {
  menuList: [],
  online: false,
  profileHoverSx: {},
  profileImage: null,
  profileSx: {},
  sx: {},
  user: {
    firstName: "",
    lastName: "",
  },
};

export default UserMenu;
