import React from "react";
import PropTypes from "prop-types";
import Box from "../base/Box";
// import Header from "../base/Header";
// import Footer from "../base/Footer";

const MainLayout = ({ children, ...rest }) => (
  <>
    {/* <Header /> /!* Add Header you Want *!/ */}
    <Box as="main" sx={{ height: "100%" }}>
      {typeof children === "function" ? children(rest) : children}
    </Box>
    {/* <Footer /> /!* Add Footer you Want *!/ */}
  </>
);

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default MainLayout;
