import React, { useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "@chakra-ui/react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import Box from "../../base/Box";
import Icon from "../../Icon";
import IsLinkMatchPath from "../../../utilities/is-link-match-path";

const MenuItemComponent = ({ menuData, sx, open }) => {
  const location = useLocation();
  const data = menuData;
  const [submenu, setSubmenu] = useState(false);
  const toggleSubmenu = () => {
    setSubmenu(!submenu);
  };
  return (
    <Box sx={sx}>
      <Box
        _hover={{
          bg: "lightGreen",
          color: "mediumGreen",
          transition: "background-color 0.1s",
        }}
        as="button"
        onClick={() => {
          if (data.submenu) {
            toggleSubmenu();
          } else if (data.path) {
            navigate(data.path);
          } else if (data.action) {
            data.action();
          }
        }}
        sx={{
          alignItems: "center",
          bg: submenu ? "lightGreen" : "none",
          color:
            submenu || IsLinkMatchPath(location, data.path)
              ? "mediumGreen"
              : "none",
          display: "flex",
          flexDirection: open ? "row" : "row-reverse",
          justifyContent: open ? "space-between" : "end",
          px: 5,
          py: 3,
          width: "100%",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: open ? "row" : "row-reverse",
            justifyContent: open ? "flex-start" : "end",
          }}
        >
          <Icon svg={data.icon} sx={{ ml: 3, width: "20px" }} />
          <Box
            sx={{
              ml: 5,
              opacity: open ? 1 : 0,
              textAlign: "left",
              transition: "opacity 0.5s",
            }}
          >
            {data.label}
          </Box>
        </Box>
        {data.submenu && (
          <Box
            sx={{
              display: open ? "flex" : "none",
              transform: submenu ? "rotate(90deg)" : "none",
              transition: "transform .3s",
            }}
          >
            <Icon svg="chevron-right" sx={{ width: "15px" }} />
          </Box>
        )}
      </Box>
      {data.submenu &&
        data.submenu.map((menuItem) => (
          <Collapse animateOpacity in={submenu}>
            <MenuItemComponent
              menuData={menuItem}
              open={open}
              sx={{
                pl: 8,
                width: "100%",
              }}
            />
          </Collapse>
        ))}
    </Box>
  );
};

MenuItemComponent.propTypes = {
  menuData: PropTypes.shape({}),
  open: PropTypes.bool,
  sx: PropTypes.shape({}),
};

MenuItemComponent.defaultProps = {
  menuData: {},
  open: false,
  sx: {},
};

export default MenuItemComponent;
