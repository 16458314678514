import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import Box from "../base/Box";
import Icon from "../Icon";
import Container from "../base/Container";
// import HeaderSearch from "../GeneralComponent/HeaderSearch";
import UserMenu from "../GeneralComponent/UserMenu";
import exampleImage from "../../images/example.png";
// import { pages } from "../../data/pages";
// import Navigation from "../base/Navigation";

const AdminHeader = ({ sx }) => {
  const { logout } = useAuth0();
  const headerSx = {
    alignItems: "center",
    bg: "white",
    boxShadow: "1",
    display: "flex",
    minHeight: "5rem",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 4,
  };
  const user = {
    firstName: "Varun",
    lastName: "Patel",
  };
  const menuList = [
    {
      data: (
        <Box>
          <Box>Signed in as</Box>
          <Box
            sx={{ fontWeight: "bold" }}
          >{`${user.firstName} ${user.lastName}`}</Box>
        </Box>
      ),
    },
    {
      section: true,
      values: [
        {
          label: "Your Profile",
          path: "#",
        },
        {
          action: () => {},
          label: "Your Activity",
        },
        {
          label: "Your Archive",
          path: "#",
        },
      ],
    },
    {
      section: true,
      values: [
        {
          label: "Features",
          path: "#",
        },
        {
          action: () => {},
          label: "Help",
        },
        {
          label: "Setting",
          path: "#",
        },
        {
          action: () => {
            logout({ returnTo: window.location.origin });
          },
          label: "Logout",
        },
      ],
    },
    /* {
      data: (
        <Box _hover={{ color: "mediumGreen" }} sx={{ color: "grays.4" }}>
          <Box>Last edited by</Box>
          <Box sx={{ fontWeight: "bold" }}>
            {`on Friday ${user.firstName} ${user.lastName}`}
          </Box>
        </Box>
      ),
    }, */
  ];
  return (
    <Box
      sx={{
        "~ main": {
          mt: 15,
        },
      }}
    >
      <Box
        as="header"
        sx={{
          ...headerSx,
          ...sx,
        }}
      >
        <Container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            as="button"
            onClick={() => {
              navigate("/");
            }}
          >
            <Icon svg="wiwi-logo" sx={{ width: "10rem" }} />
          </Box>

          {/* <HeaderSearch /> */}
          {/* {pages && pages.length && (
            <Navigation navLinks={pages} toggled={false} />
          )} */}
          <UserMenu
            menuList={menuList}
            online
            profileImage={exampleImage}
            user={user}
          />
        </Container>
      </Box>
    </Box>
  );
};

AdminHeader.propTypes = {
  sx: PropTypes.shape({}),
};

AdminHeader.defaultProps = {
  sx: {},
};

export default AdminHeader;
