import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import Box from "../../base/Box";
import Icon from "../../Icon";

const SideNavigation = ({ children, menuSx, logo, headingSx }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const drop = useRef(null);
  const outerClick = (e) => {
    if (
      !e.target.closest(`.${drop.current.className}`) &&
      (toggleOpen || toggleOpen)
    ) {
      setOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", outerClick);
    return () => {
      document.removeEventListener("click", outerClick);
    };
  });
  const menuStyle = {
    bg: "bgMenu",
    color: "white",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    left: open ? 0 : "-13rem",
    maxWidth: "17rem",
    minWidth: "17rem",
    p: 5,
    position: "fixed",
    top: 0,
    transition: "left 1s",
    zIndex: 5,
    ...menuSx,
  };
  return (
    <Box
      ref={drop}
      sx={{
        ...menuStyle,
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          height: "30px",
          justifyContent: "space-between",
          mt: 3,
          transition: "all 1s ease",
          width: "100%",
          ...headingSx,
        }}
      >
        <Box
          as="button"
          onClick={() => {
            navigate("/");
          }}
        >
          <Icon
            svg={logo}
            sx={{
              opacity: open ? 1 : 0,
              transition: "opacity 0.5s",
              width: "10rem",
            }}
          />
        </Box>
        <Icon
          _hover={{
            color: "mediumGreen",
            transition: "color .2s",
          }}
          as="button"
          onClick={() => {
            toggleOpen();
          }}
          svg="forward-arrow"
          sx={{
            color: "white",
            mr: 2,
            transform: open ? "rotate(180deg)" : "none",
            transition: "transform 0.2s",
            width: "20px",
          }}
        />
      </Box>
      {children({ open })}
    </Box>
  );
};

SideNavigation.propTypes = {
  children: PropTypes.func.isRequired,
  headingSx: PropTypes.shape({}),
  logo: PropTypes.string,
  menuSx: PropTypes.shape({}),
};

SideNavigation.defaultProps = {
  headingSx: {},
  logo: "",
  menuSx: {},
};

export default SideNavigation;
