import React from "react";
import PropTypes from "prop-types";
import { Image } from "@chakra-ui/react";
import noImage from "../../../images/no-image.png";

const Img = ({ src, sx, ...rest }) => {
  const imgStyled = {
    display: "inline-block",
    maxWidth: "100%",
    verticalAlign: "top",
    ...sx,
  };
  return <Image fallbackSrc={noImage} src={src} sx={imgStyled} {...rest} />;
};
Img.propTypes = {
  src: PropTypes.string,
  sx: PropTypes.shape({}),
};

Img.defaultProps = {
  src: "",
  sx: {},
};

export default Img;
