import React from "react";
import { Location } from "@reach/router";
import PageHandler from "./PageHandler";
import { pageTypes } from "./utilities/types";

const Page = (props) => (
  <Location>{(location) => <PageHandler {...location} {...props} />}</Location>
);

Page.propTypes = pageTypes;

export default Page;
